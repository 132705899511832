
import { defineComponent, Suspense, toRef, computed } from "vue";
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import CardBaseService from "@/view/content/widgets/feeds/CardBaseService.vue";

export default defineComponent({
  name: "base-service",
  components: {
    HeaderPage,
    CardBaseService,
  },
  props: {
    title: String,
    subtitle: String,
    description: String,
    description1: String,
    description2: String,
    headerBanner: String,
    isSearchable: Boolean,
    searchPlaceholder: String,
    collection: Array,
    breadcrumbs: Object,
  },
  setup(props) {
    const title = toRef(props, "title");
    const subtitle = toRef(props, "subtitle");
    const searchPlaceholder = toRef(props, "searchPlaceholder");
    const description = toRef(props, "description");
    const description1 = toRef(props, "description1");
    const description2 = toRef(props, "description2");
    const isSearchable = toRef(props, "isSearchable");
    const collection = toRef(props, "collection");
    const breadcrumbs = toRef(props, "breadcrumbs");

    const breadcrumbsCollection = computed(() => {
      return breadcrumbs.value ? breadcrumbs.value : {};
    });

    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const descriptionText = computed(() => {
      return description.value ? description.value : "";
    });

    const descriptionText1 = computed(() => {
      return description1.value ? description1.value : "";
    });

    const descriptionText2 = computed(() => {
      return description2.value ? description2.value : "";
    });

    const isSearchableCondition = computed(() => {
      return isSearchable.value ? isSearchable.value : false;
    });

    const searchPlaceholderText = computed(() => {
      return searchPlaceholder.value ? searchPlaceholder.value : "";
    });

    const collectionData = computed(() => {
      return collection.value ? collection.value : "";
    });

    return {
      titleText,
      subtitleText,
      descriptionText,
      descriptionText1,
      descriptionText2,
      isSearchableCondition,
      searchPlaceholderText,
      collectionData,
      breadcrumbsCollection,
    };
  },
});
