import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row g-3 g-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_page = _resolveComponent("header-page")!
  const _component_card_base_service = _resolveComponent("card-base-service")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_page, {
      "bg-image": _ctx.headerBanner,
      "bg-image-small": _ctx.headerBanner,
      "bg-position": "",
      title: _ctx.titleText,
      subtitle: _ctx.subtitleText,
      description: _ctx.descriptionText,
      description1: _ctx.descriptionText1,
      description2: _ctx.descriptionText2,
      "is-searchable": _ctx.isSearchableCondition,
      search: _ctx.searchPlaceholderText,
      breadcrumbs: _ctx.breadcrumbs,
      lg: false,
      bgHex: "#fff",
      margin: "mt-2 mb-7"
    }, null, 8, ["bg-image", "bg-image-small", "title", "subtitle", "description", "description1", "description2", "is-searchable", "search", "breadcrumbs"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.headerBanner)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "col-12 d-md-flex my-5 mx-5 flex-row-fluid my-auto bgi-no-repeat bgi-size-cover mb-6",
            style: _normalizeStyle([{ backgroundImage: 'url(' + _ctx.headerBanner + ')' }, {"min-height":"70px","border-radius":"0.5rem"}])
          }, null, 4))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collectionData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "col-xl-6"
            }, [
              _createVNode(_component_card_base_service, {
                title: item.name,
                subtitle: item.metadata.address,
                website: item.metadata.website,
                bio: item.shortDescription,
                image: item.metadata.images[0].href
              }, null, 8, ["title", "subtitle", "website", "bio", "image"])
            ]))
          }), 128))
        ])
      ]),
      fallback: _withCtx(() => [
        _createTextVNode(" Loading... ")
      ]),
      _: 1
    }))
  ], 64))
}